import React, { useContext } from 'react';
import * as S from './ProductImagesTrigger.styles';
import './ProductsImagesTrigger.css';
import FunnelContext from '../../context/FunnelContext';

const ProductImagesTrigger = ({
  showChildIndex,
  children,
  activeClassName
}) => {
  const { setProductImageIndex } = useContext(FunnelContext);

  const handleClick = () => {
    setProductImageIndex(showChildIndex);
  };

  return (
    <S.Container onClick={handleClick} className={activeClassName || `design1`}>
      {children}
    </S.Container>
  );
};

export default ProductImagesTrigger;
